import React, { useEffect, useState } from 'react';
import { Button, FormGroup, InputField, Label, ModalTitle, Select } from '../SharedComponents';
import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import { OrderInterface, OrderProductInterface } from '../../types';
import apiClient from '../../services/apiClient';
import CustomDatePicker from '../CustomDatePicker';
import { Moment } from 'moment';
import moment from 'moment';

interface EditOrderFormProps {
	order: any;
	products: any;
	isOrderProductsLoading: boolean;
	cancelEdit: () => void;
	handleHidePanel: (order?: OrderInterface) => void;
}

const productItemWrapper = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	flexWrap: 'wrap',
	padding: '10px',
	border: '1px solid #c4c4c4',
	borderRadius: '8px',
	marginBottom: '10px',
	boxShadow: '0px 3px 4px 0px rgb(221 232 235 / 61%)',
	'@media (max-width: 768px)': {

		// flexDirection: 'column'
	}
}

const productSelectStyle = {
	maxWidth: '100%',
}

const productInputWrapperStyle = {
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	width: '100%'
}

const productUnitPriceWrapper = {
	marginRight: '20px',
	maxWidth: '25%',
}

const productQuantityWrapper = {
	maxWidth: '25%',
}

const fieldRowStyle = {
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	'@media (max-width: 768px)': {
		flexWrap: 'wrap',
		flexDirection: 'column'
	}
}



const AddProductButton = styled.div`
    padding: 10px;
    width: 100%;
    border-radius: 8px;
    border: 1px dashed black;
    color: black;
    cursor: pointer;
    margin-top: 20px;
    text-align: center;
    box-sizing: border-box;
`;

const fetchAllInventory = () => {
	return apiClient.get('/inventory');
};

const fetchCustomers = (searchTerm: string) => {
	return apiClient.get(`/orders/search-customer?customer-name=${searchTerm}`);
}

const submitOrder = (orderData: OrderInterface) => {
	const isEditing = orderData.id ? true : false;

	if (isEditing) {
		return apiClient.update(`/orders/${orderData.id}`, orderData);
	}

	return apiClient.post('/orders', orderData);

};

interface EditOrderModalProductsListInterface {
	products: OrderProductInterface[];
	handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number, inventoryList?: any[]) => void;
	addProductSlot: (a: number, b: number) => void;
	removeProductSlot: (index: number) => void;
	isNewOrder: boolean
}

const EditOrderModalProductsList = ({ products: orderProducts, handleChange, addProductSlot, removeProductSlot, isNewOrder }: EditOrderModalProductsListInterface) => {
	const [inventoryList, setInventoryList] = useState<any[]>([]);
	const [isFetchingInventory, setIsFetchingInventory] = useState<boolean>(false);

	useEffect(() => {
		setIsFetchingInventory(true);
		fetchAllInventory().then((response: any) => {
			console.log("response", response);
			let inventoryList = response.data ? response.data : [];
			setInventoryList(inventoryList);
			setIsFetchingInventory(false);
		});
	}, []);

	if (isFetchingInventory) {
		return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '20px 0px' }}><CircularProgress size={30} /></div>;
	}

	return (
		<>
			{orderProducts && orderProducts.map((orderProduct: OrderProductInterface, index: number) => {
				return (
					<Box sx={productItemWrapper} style={{ width: '100%' }} key={index}>
						<FormGroup sx={productSelectStyle}>
							<Label>Product</Label>
							<Select name="id" value={orderProduct.id} onChange={(e) => handleChange(e, index, inventoryList)} style={{ padding: '15px' }}>
								{inventoryList.map((inventoryListItem: any, inventoryItemIdx: number) => (
									<option disabled={inventoryListItem.total_quantity === 0} value={inventoryListItem.product_id} key={inventoryListItem.id}>{inventoryListItem.product_name} [{inventoryListItem.total_quantity ?? 0}]</option>
								))}
							</Select>
						</FormGroup>
						<Box sx={productInputWrapperStyle}>
							<FormGroup sx={productUnitPriceWrapper}>
								<Label>Unit Price</Label>
								<InputField style={{ padding: '15px' }} type="number" name="unit_price_at_purchase" value={orderProduct.unit_price_at_purchase} onChange={(e) => handleChange(e, index)} />
							</FormGroup>
							<FormGroup sx={productUnitPriceWrapper}>
								<Label>Discount Amount</Label>
								<InputField style={{ padding: '15px' }} type="number" name="discount_amount" value={orderProduct.discount_amount} onChange={(e) => handleChange(e, index)} />
							</FormGroup>
							<FormGroup sx={productQuantityWrapper}>
								<Label>Quantity</Label>
								<InputField min="1" type="number" name="quantity" value={orderProduct.quantity} style={{ textAlign: 'center', padding: '15px' }} onChange={(e) => handleChange(e, index)} />
							</FormGroup>
							<div onClick={() => removeProductSlot(index)} style={{ marginLeft: '10px', marginTop: '20px', maxWidth: '15%', cursor: 'pointer' }}><DeleteIcon /></div>
						</Box>
					</Box>
				);
			})}
			<AddProductButton onClick={() => addProductSlot(Number(inventoryList[0].price), Number(inventoryList[0].product_id))}>Add New</AddProductButton>
		</>
	);
};

const EditOrderForm = ({ order: initialOrder, products: initialProducts, isOrderProductsLoading, cancelEdit, handleHidePanel }: EditOrderFormProps) => {
	const [order, setOrder] = useState<OrderInterface>(initialOrder);
	const [orderProducts, setOrderProducts] = useState<OrderProductInterface[]>(initialProducts);
	const [isSubmittingOrder, setIsSubmittingOrder] = useState<boolean>(false);
	const [orderDate, setOrderDate] = useState<Moment | null>(null);
	const [customers, setCustomers] = useState<any[]>([{ customer_name: '', customer_email: '', customer_phone: '', customer_address: '' }]);
	const [isFetchingCustomers, setIsFetchingCustomers] = useState(false);

	// useEffect(() => {
	// 	if (order.customer_name && order.customer_name.length > 3) {
	// 		fetchCustomers(order.customer_name).then((response: any) => {
	// 			console.log("customers", response);
	// 			setCustomers(response.data);
	// 		})
	// 	}
	// }, [order.customer_name]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setOrder({ ...order, [name]: value });
		if (name === 'customer_name') {
			console.log("value", value);
			// setCustomers([{ customer_name: value }]);
		}

	};

	const handleCustomerSearch = async (searchTerm: string) => {
		if (searchTerm.length > 2) {
			setIsFetchingCustomers(true);
			try {
				const response = await fetchCustomers(searchTerm);
				setCustomers(response.data || []);
			} catch (error) {
				console.error("Error fetching customers", error);
			} finally {
				setIsFetchingCustomers(false);
			}
		}
	};

	const handleCustomerChange = (customerData: any) => {
		setOrder((prevOrder) => ({
			...prevOrder,
			customer_name: customerData.customer_name || '',
			customer_email: customerData.customer_email || '',
			customer_address: customerData.customer_address || '',
			customer_phone: customerData.customer_phone || '',
		}));
	};

	const handleOrderProductsChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number, inventoryList: any[] = []) => {
		const { name } = e.target;
		let value = Number(e.target.value);
		let newOrderProducts: OrderProductInterface[] = [...orderProducts];
		newOrderProducts[index] = {
			...newOrderProducts[index],
			[name]: value
		};

		if (name === 'id' && e.target instanceof HTMLSelectElement) {
			const selectedIndex = e.target.selectedIndex;

			newOrderProducts[index] = {
				...newOrderProducts[index],
				unit_price_at_purchase: inventoryList[selectedIndex].price,
			};

		}
		setOrderProducts(newOrderProducts);
	};

	// useEffect(() => {
	// 	console.log("order changed", order);
	// }, [order]);

	// useEffect(() => {
	// 	console.log("products changed", orderProducts);
	// }, [orderProducts]);

	useEffect(() => {
		setOrder(initialOrder);
	}, [initialOrder]);

	useEffect(() => {
		setOrder({
			...order,
			backdated_order_date: orderDate
		})
	}, [orderDate])

	useEffect(() => {
		console.log("initialOrder", initialOrder);
		if (initialOrder.backdated_order_date) {
			console.log("initialOrder.backdated_order_date", initialOrder.backdated_order_date);
			setOrderDate(moment(initialOrder.backdated_order_date));
		} else {
			console.log("initialOrder.date_created", initialOrder.date_created);
			setOrderDate(moment(initialOrder.date_created));
		}
	}, [initialOrder])

	useEffect(() => {
		setOrderProducts(initialProducts);
	}, [initialProducts]);


	const isInitialOrderEmpty = isEqual(initialOrder, {
		customer_name: '',
		customer_email: '',
		customer_phone: '',
		customer_address: '',
		is_order_paid: false,
	});

	const handleSave = () => {
		setIsSubmittingOrder(true);

		let orderData = {
			...order,
			products: orderProducts
		};
		console.log("orderData", orderData);

		submitOrder(orderData).then((response: any) => {
			handleHidePanel(response.data);
		}).catch((error: any) => {
			console.error("error submitting order", error);
		}).finally(() => {
			setIsSubmittingOrder(false);
		})
	};

	const addProductSlot = (defaultPrice: number, defaultId: number) => {
		setOrderProducts([...orderProducts, {
			id: defaultId,
			unit_price_at_purchase: defaultPrice,
			quantity: 1,
			discount_amount: 0
		}]);
	};

	const removeProductSlot = (index: number) => {
		let newOrderProducts = [...orderProducts];
		newOrderProducts.splice(index, 1);
		setOrderProducts(newOrderProducts);
	};

	const handleDateChange = (date: Moment | null) => {
		setOrderDate(date);
	};

	return (
		<>
			<ModalTitle>{isInitialOrderEmpty ? "Create" : "Edit"} Order</ModalTitle>
			<Box sx={fieldRowStyle}>
				<FormGroup style={{ marginRight: '20px' }}>
					<Label style={{ visibility: 'hidden' }}>Ordered By</Label>
					{/* <InputField type="text" name="customer_name" value={order.customer_name} onChange={handleChange} /> */}
					<Autocomplete
						freeSolo
						options={customers}
						getOptionLabel={(option) => option.customer_name || ''}
						loading={isFetchingCustomers}
						onInputChange={(event, newInputValue) => {
							handleCustomerSearch(newInputValue);
							handleCustomerChange({ customer_name: newInputValue });
						}}
						onChange={(event, newValue) => {
							if (newValue) {
								handleCustomerChange(newValue);
							}
						}}
						value={order.customer_name ? { customer_name: order.customer_name } : null}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Ordered by"
								fullWidth
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<>
											{isFetchingCustomers ? <CircularProgress color="inherit" size={20} /> : null}
											{params.InputProps.endAdornment}
										</>
									),
								}}
							/>
						)}
					/>
				</FormGroup>
				<FormGroup>
					<Label>Delivery Address</Label>
					<InputField type="text" name="customer_address" value={order.customer_address} onChange={handleChange} />
				</FormGroup>
			</Box>
			<Box sx={fieldRowStyle}>
				<FormGroup style={{ marginRight: '20px' }}>
					<Label>Phone</Label>
					<InputField type="text" name="customer_phone" value={order.customer_phone} onChange={handleChange} />
				</FormGroup>
				<FormGroup>
					<Label>Email</Label>
					<InputField type="text" name="customer_email" value={order.customer_email} onChange={handleChange} />
				</FormGroup>
			</Box>
			<FormGroup style={{ marginTop: '10px' }}>
				<CustomDatePicker
					label="Date Ordered"
					value={orderDate}
					onChange={handleDateChange}
				/>
			</FormGroup>
			<hr style={{ margin: '50px 0px 30px 0px', backgroundColor: '#f3f3f3', border: 'none', height: '1px' }} />
			<div>
				<h4>Products</h4>
				{isOrderProductsLoading ? (
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '50px 0px' }}>
						<CircularProgress size={30} color="inherit" />
					</div>
				) : (
					<EditOrderModalProductsList
						isNewOrder={isInitialOrderEmpty}
						products={orderProducts}
						handleChange={handleOrderProductsChange}
						addProductSlot={addProductSlot}
						removeProductSlot={removeProductSlot}
					/>
				)}
			</div>
			<Box sx={fieldRowStyle}>
				<Button style={{ marginRight: '20px', flex: 2 }} onClick={handleSave}>
					{isSubmittingOrder ? <CircularProgress size={15} color="inherit" /> : 'Save'}
				</Button>
				{order.id && <Button style={{ flex: 1 }} onClick={cancelEdit}>Cancel</Button>}
			</Box>
		</>
	);
};

export default EditOrderForm;
