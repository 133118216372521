import { getGridDateOperators, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

const validDateFields = ['date_created', 'date_updated', 'backdated_expense_date', 'backdated_order_date']

//this function will update the date columns to use a date filter
export const formatColumnDateFields = (columns: GridColDef[]): GridColDef[] => {
    return columns.map((column: GridColDef) => {
        if (validDateFields.includes(column.field)) {
            console.log("field", column.field)
            return {
                ...column,
                // valueFormatter: ({ value }) => (value === null ? '' : formatDateField(value as string)),
                // valueGetter: (params: GridRenderCellParams) => (params.value ? new Date(params.value) : null),
                filterOperators: getGridDateOperators(),
                renderCell: (params: GridRenderCellParams) => { return "test" },
                // filterOperators: getGridDateOperators().filter((operator) =>
                //     Object.keys(operatorMapper).includes(operator.value)
                // ),
            };
        }

        return column;
    });
};